var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-space", { attrs: { direction: "vertical" } }, [
                _c("p", { staticClass: "text-subtitle-1" }, [
                  _vm._v(" " + _vm._s(_vm.$t("lbl_basic_feature")) + " ")
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "a-space",
                      { attrs: { size: 90 } },
                      [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_basic_invoice_term")) + " "
                          )
                        ]),
                        _c(
                          "a-select",
                          {
                            staticStyle: { width: "200px" },
                            attrs: { placeholder: _vm.$t("lbl_choose") },
                            on: { change: _vm.onChangeTop },
                            model: {
                              value: _vm.valPref.purchase_top,
                              callback: function($$v) {
                                _vm.$set(_vm.valPref, "purchase_top", $$v)
                              },
                              expression: "valPref.purchase_top"
                            }
                          },
                          _vm._l(_vm.optBasicInvoiceTerm, function(data) {
                            return _c(
                              "a-select-option",
                              { key: data.value, attrs: { value: data.value } },
                              [
                                _c(
                                  "a-tooltip",
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            data.value === "0"
                                              ? _vm.$t(data.key)
                                              : (data.value === "-1"
                                                  ? _vm.$t(
                                                      "lbl_cash_in_advance"
                                                    )
                                                  : _vm.$t("lbl_top_in_day", {
                                                      value: data.value
                                                    })) || "-"
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.value === "0"
                                            ? _vm.$t(data.key)
                                            : (data.value === "-1"
                                                ? _vm.$t("lbl_cash_in_advance")
                                                : _vm.$t("lbl_top_in_day", {
                                                    value: data.value
                                                  })) || "-"
                                        ) +
                                        " "
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("a-checkbox-group", {
                      staticClass: "check-basic",
                      attrs: { options: _vm.optBasicFeature },
                      on: { change: _vm.onChecked },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function(ref) {
                            var value = ref.value
                            return _c("span", {}, [
                              _vm._v(_vm._s(_vm.generateLabel(value)))
                            ])
                          }
                        }
                      ]),
                      model: {
                        value: _vm.valPref.basic,
                        callback: function($$v) {
                          _vm.$set(_vm.valPref, "basic", $$v)
                        },
                        expression: "valPref.basic"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c("p", { staticClass: "text-subtitle-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("lbl_import_cost")) + " ")
            ]),
            _c("table", [
              _c("tr", [
                _c("td", { attrs: { width: "120px" } }, [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_vat_tax")) + " ")
                  ])
                ]),
                _c(
                  "td",
                  { attrs: { width: "200px" } },
                  [
                    _c("CSelectTaxCode", {
                      attrs: { "tax-type": _vm.TAX_TYPE.VAT_IN },
                      on: { "on-select": _vm.emitData },
                      model: {
                        value: _vm.valPref.purchase_import_cost_ppn,
                        callback: function($$v) {
                          _vm.$set(_vm.valPref, "purchase_import_cost_ppn", $$v)
                        },
                        expression: "valPref.purchase_import_cost_ppn"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("tr", [
                _c("td", [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_income_tax")) + " ")
                  ])
                ]),
                _c(
                  "td",
                  [
                    _c("CSelectTaxCode", {
                      attrs: { "tax-type": _vm.TAX_TYPE.INCOME_TAX },
                      on: { "on-select": _vm.emitData },
                      model: {
                        value: _vm.valPref.purchase_import_cost_pph_22,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.valPref,
                            "purchase_import_cost_pph_22",
                            $$v
                          )
                        },
                        expression: "valPref.purchase_import_cost_pph_22"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }