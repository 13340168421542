import { OptionModel } from "@/models/interface/common.interface";
import { BASIC_FEATURE } from "../enums/option-purchases.enum";

export const OPTION_BASIC_FEATURE: OptionModel[] = [
    {
        value: BASIC_FEATURE.QUOTATION_AND_PURCHASE_ORDER
    },
    {
        value: BASIC_FEATURE.PURCHASE_ORDER_RETURN
    },
];