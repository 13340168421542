





































































































import Vue, { PropType } from "vue";
import { OPTION_BASIC_FEATURE } from "./constant/optionpurchases.constant";
import { COMPONENT_NAME } from "./Preferences.vue";
import { masterServices } from "@/services/master.service";
import { BASIC_FEATURE } from "./enums/option-purchases.enum";
import { TAX_TYPE } from "@/models/enums/tax.enum";
import { IOption } from "@/models/interface-v2/common.interface";
export interface IPrefPurchases {
  purchase_top: string;
  basic: string[];
  purchase_import_cost_ppn: string;
  purchase_import_cost_pph_22: string;
}
export default Vue.extend({
  name: "PreferencesPurchases",
  props: {
    firstLoad: Boolean,
    dataFirstLoad: {} as PropType<IPrefPurchases>,
  },
  components: {
    CSelectTaxCode: () => import(/*webpackPrefetch: true */"@/components/shared/select-tax-code/CSelectTaxCode.vue")
  },
  data() {
    return {
      TAX_TYPE,
      optBasicInvoiceTerm: [] as IOption[],
      optBasicFeature: OPTION_BASIC_FEATURE,
      purchaseCheckedList: [],
      valPref: {
        purchase_top: undefined as string | undefined,
        basic: [] as string[],
        purchase_import_cost_ppn: undefined as string | undefined,
        purchase_import_cost_pph_22: undefined as string | undefined,
      } as IPrefPurchases,
    };
  },
  watch: {
    dataFirstLoad: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstLoad && newValue) {
          this.valPref.basic = newValue.basic;
          this.valPref.purchase_top = newValue.purchase_top;
          this.valPref.purchase_import_cost_ppn = newValue.purchase_import_cost_ppn;
          this.valPref.purchase_import_cost_pph_22 = newValue.purchase_import_cost_pph_22;
          this.$emit("onChangeLoad", {
            name: COMPONENT_NAME.PREF_PURCHASES,
            val: true,
          });
        }
      },
    },
  },
  created() {
    this.getTOP();
  },
  methods: {
    getTOP(): void {
      masterServices.listMaster({ name: "TOP" }).then((responseTop) => {
        this.optBasicInvoiceTerm = responseTop.map(x => {
          return { key: this.getTOPLabel(x.value), value: x.value };
        });
      });
    },
    getTOPLabel(top: string): string {
      if (top === "0") {
        return "lbl_cash_on_delivery";
      } else if (top === "-1") {
        return "lbl_cash_on_advance";
      } else {
        return top;
      }
    },
    generateLabel(key: string | BASIC_FEATURE): string {
      if (key === BASIC_FEATURE.QUOTATION_AND_PURCHASE_ORDER)
        return this.$t("lbl_quotation_and_purchase_order").toString();
      if (key === BASIC_FEATURE.PURCHASE_ORDER_RETURN)
        return this.$t("lbl_purchase_order_return").toString();
      return key;
    },
    onChangeTop(value, option): void {
      // this.valPref.purchase_top = value;
      // this.$emit("onChange", {
      //   name: COMPONENT_NAME.PREF_PURCHASES,
      //   val: this.valPref,
      // });
      this.emitData();
    },
    onChecked(checkedValues): void {
      // this.valPref.basic = checkedValues;
      // this.$emit("onChange", {
      //   name: COMPONENT_NAME.PREF_PURCHASES,
      //   val: this.valPref,
      // });
      this.emitData();
    },
    emitData(): void {
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_PURCHASES,
        val: this.valPref,
      });      
    },
  },
});
